define("rate-your-tow-ui/templates/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zqh5KssP",
    "block": "[[[41,[30,0,[\"session\",\"isAuthenticated\"]],[[[1,\"  There was an error\\n\"]],[]],[[[1,\"  You have been logged out.\\n\"]],[]]]],[],false,[\"if\"]]",
    "moduleName": "rate-your-tow-ui/templates/logout.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});