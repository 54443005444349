define("rate-your-tow-ui/components/members/member-form", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  <BsForm @formLayout="horizontal" @model={{@member}} @onSubmit={{action "onSubmit"}} showValidation={{true}} as |form|>
    <form.element @label="Name" @property="name" />
    <form.element @controlType="email" @label="Email" @placeholder="Email" @property="config.email" />
    <form.element @label="Included Texts" @property="config.includedTexts" />
  
    {{#if @member.errors}}
      <div class="formgroup row">
        <div class="col-md-4"></div>
        <div class="col-md-8 alert alert-danger" role="alert">
          <ul class="ryt-errors">
            {{#each @member.errors as |error|}}
              <li>{{error.attribute}} {{error.message}}</li>
            {{/each}}
          </ul>
        </div>
      </div>
    {{/if}}
    
    <form.submitButton>Submit</form.submitButton>
  </BsForm>
  
  */
  {
    "id": "Qb7aufDu",
    "block": "[[[1,\"\\n\"],[8,[39,0],[[16,\"showValidation\",true]],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"horizontal\",[30,1],[28,[37,1],[[30,0],\"onSubmit\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"element\"]],null,[[\"@label\",\"@property\"],[\"Name\",\"name\"]],null],[1,\"\\n  \"],[8,[30,2,[\"element\"]],null,[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\"],[\"email\",\"Email\",\"Email\",\"config.email\"]],null],[1,\"\\n  \"],[8,[30,2,[\"element\"]],null,[[\"@label\",\"@property\"],[\"Included Texts\",\"config.includedTexts\"]],null],[1,\"\\n\\n\"],[41,[30,1,[\"errors\"]],[[[1,\"    \"],[10,0],[14,0,\"formgroup row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-4\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-md-8 alert alert-danger\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"ryt-errors\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"errors\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,[30,3,[\"attribute\"]]],[1,\" \"],[1,[30,3,[\"message\"]]],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \\n  \"],[8,[30,2,[\"submitButton\"]],null,null,[[\"default\"],[[[[1,\"Submit\"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@member\",\"form\",\"error\"],false,[\"bs-form\",\"action\",\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "rate-your-tow-ui/components/members/member-form.hbs",
    "isStrictMode": false
  });

  let MembersMemberFormComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class MembersMemberFormComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    onSubmit(member) {
      console.log(member);
      member.save().catch(reason => {
        console.log('save failed', member, reason);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype)), _class));
  _exports.default = MembersMemberFormComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MembersMemberFormComponent);
});