define("rate-your-tow-ui/templates/members/member_modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uliK1yFh",
    "block": "[[[8,[39,0],null,[[\"@onHidden\"],[[28,[37,1],[[30,0],[33,2]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,\"Edit \"],[1,[30,0,[\"model\",\"name\"]]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"horizontal\",[30,0,[\"model\"]],[28,[37,1],[[30,0],\"submitForm\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"element\"]],null,[[\"@label\",\"@property\"],[\"Name\",\"name\"]],null],[1,\"\\n      \"],[8,[30,2,[\"element\"]],null,[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\"],[\"email\",\"Email\",\"Email\",\"config.email\"]],null],[1,\"\\n      \"],[8,[30,2,[\"element\"]],null,[[\"@label\",\"@property\"],[\"Included Texts\",\"config.includedTexts\"]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@onClick\",\"@type\"],[[28,[37,1],[[30,0],[30,1,[\"close\"]]],null],\"danger\"]],[[\"default\"],[[[[1,\"Oh no, forget it!\"]],[]]]]],[1,\"\\n    \"],[8,[39,4],null,[[\"@onClick\",\"@type\"],[[28,[37,1],[[30,0],[30,1,[\"submit\"]]],null],\"success\"]],[[\"default\"],[[[[1,\"Yeah!\"]],[]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]]]],[\"Modal\",\"form\",\"footer\"],false,[\"bs-modal\",\"action\",\"doClose\",\"bs-form\",\"bs-button\"]]",
    "moduleName": "rate-your-tow-ui/templates/members/member_modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});