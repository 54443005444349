define("rate-your-tow-ui/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii", "rate-your-tow-ui/config/environment", "fetch"], function (_exports, _torii, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ToriiAuthenticator = (_dec = Ember.inject.service, (_class = class ToriiAuthenticator extends _torii.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "torii", _descriptor, this);

      _defineProperty(this, "_EXPIRATION_PADDING", 60);
    }

    // 3560;
    async authenticate() {
      const data = await super.authenticate(...arguments);
      const tokenRequestParams = {
        grant_type: 'authorization_code',
        code: data.authorizationCode,
        redirect_uri: data.redirectUri,
        code_verifier: data.state
      };
      return this.fetchTokens(tokenRequestParams).then(resp => {
        // Add some extra info to the state
        resp.email = this.parseJwt(resp.idToken).email;
        resp.provider = data.provider;
        return resp;
      });
    } // authenticate ()


    async fetchTokens(params) {
      const tokenExchangeUri = _environment.default.torii.providers.cognito.tokenUri;
      params.client_id = _environment.default.torii.providers.cognito.clientId;
      return (0, _fetch.default)(tokenExchangeUri, {
        method: 'POST',
        // no-cors, *cors, same-origin
        mode: 'cors',
        body: this.getParamString(params),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(resp => {
        // Extract JSON
        return resp.json();
      }).then(resp => {
        // Caclulate expiration time
        const expAt = new Date();
        expAt.setSeconds(expAt.getSeconds() + Number(resp.expires_in) - this._EXPIRATION_PADDING);
        const buff = {
          accessToken: resp.access_token,
          expiresAt: expAt,
          idToken: resp.id_token,
          refreshToken: resp.refresh_token
        };
        return buff;
      });
    } // fetchTokens ()


    async restore(data) {
      console.log('restoring', data);
      const exp = new Date(data.expiresAt);
      const now = new Date();

      if (exp.getTime() < now.getTime()) {
        console.log('Refreshing expired token');
        const tokenRequestParams = {
          grant_type: 'refresh_token',
          refresh_token: data.refreshToken
        };
        return this.fetchTokens(tokenRequestParams).then(response => {
          delete response.refreshToken;
          console.log('new tokens', response);
          return super.restore(Object.assign(data, response));
        });
      } else {
        // Token is still valid
        return super.restore(data);
      }
    } // Wrap up params for POSTing


    getParamString(params) {
      const buff = [];

      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          buff.push([key, params[key]].join('='));
        }
      }

      return buff.join('&');
    } // Parse JWT and return json representation.


    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "torii", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ToriiAuthenticator;
});