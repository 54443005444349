define("rate-your-tow-ui/templates/members/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v+RUdtNh",
    "block": "[[[10,0],[14,1,\"members_list\"],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table table-hover\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Name\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Usage\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Last Message\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,2],null,[[\"@member\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\" \"],[3,\" #members_list \"]],[\"@model\",\"member\"],false,[\"each\",\"-track-array\",\"members/member-row\"]]",
    "moduleName": "rate-your-tow-ui/templates/members/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});