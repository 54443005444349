define("rate-your-tow-ui/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j9TeD9I+",
    "block": "[[[1,[28,[35,0],[\"RateYourTowUi\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13],[1,\" \"],[3,\" .container \"],[1,\"\\n\\n\"],[10,0],[14,1,\"modal_container\"],[12],[1,\"\\n  \"],[46,[28,[37,3],[\"modal\"],null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"script\"],[14,\"src\",\"https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js\"],[14,\"integrity\",\"sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4\"],[14,\"crossorigin\",\"anonymous\"],[12],[13]],[],false,[\"page-title\",\"navbar\",\"component\",\"-outlet\"]]",
    "moduleName": "rate-your-tow-ui/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});