define("rate-your-tow-ui/components/members/member-row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
    <td>{{@member.name}}</td>
    <td>{{@member.usage}} / {{@member.config.includedTexts}}</td>
    <td>{{@member.last_message}}</td>
    <td>
      <div class="dropdown">
        <button class="btn btn-primary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          Actions
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <LinkTo class="dropdown-item" @route="members.edit" @model={{@member}}>
              Edit
            </LinkTo>
          </li>
          <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#editMemberModal">Old Edit</a></li>
          <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#keysModal">API Keys</a></li>
          <li><a class="dropdown-item" href="#">Disable</a></li>
        </ul>
      </div>
    </td>
  </tr>
  */
  {
    "id": "DV2AW2Yh",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,[30,1,[\"usage\"]]],[1,\" / \"],[1,[30,1,[\"config\",\"includedTexts\"]]],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,[30,1,[\"last_message\"]]],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dropdown\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary btn-sm dropdown-toggle\"],[14,1,\"dropdownMenuButton1\"],[14,\"data-bs-toggle\",\"dropdown\"],[14,\"aria-expanded\",\"false\"],[14,4,\"button\"],[12],[1,\"\\n        Actions\\n      \"],[13],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[14,\"aria-labelledby\",\"dropdownMenuButton1\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,0],[[24,0,\"dropdown-item\"]],[[\"@route\",\"@model\"],[\"members.edit\",[30,1]]],[[\"default\"],[[[[1,\"\\n            Edit\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[14,0,\"dropdown-item\"],[14,6,\"#\"],[14,\"data-bs-toggle\",\"modal\"],[14,\"data-bs-target\",\"#editMemberModal\"],[12],[1,\"Old Edit\"],[13],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[14,0,\"dropdown-item\"],[14,6,\"#\"],[14,\"data-bs-toggle\",\"modal\"],[14,\"data-bs-target\",\"#keysModal\"],[12],[1,\"API Keys\"],[13],[13],[1,\"\\n        \"],[10,\"li\"],[12],[10,3],[14,0,\"dropdown-item\"],[14,6,\"#\"],[12],[1,\"Disable\"],[13],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@member\"],false,[\"link-to\"]]",
    "moduleName": "rate-your-tow-ui/components/members/member-row.hbs",
    "isStrictMode": false
  });

  let MembersMemberRowComponent = (_dec = Ember._action, (_class = class MembersMemberRowComponent extends _component.default {
    doEdit(member, event) {
      event.preventDefault();
      const appRoute = Ember.getOwner(this).lookup('route:application');
      const memberController = appRoute.controllerFor('member');
      memberController.model = member;
      console.log('Actioning');
      appRoute.render('members/member', {
        outlet: 'modal',
        into: 'application',
        controller: memberController,
        member
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "doEdit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "doEdit"), _class.prototype)), _class));
  _exports.default = MembersMemberRowComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MembersMemberRowComponent);
});