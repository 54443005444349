define("rate-your-tow-ui/torii-providers/cognito", ["exports", "torii/providers/oauth2-code", "rate-your-tow-ui/config/environment", "torii/lib/query-string"], function (_exports, _oauth2Code, _environment, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // import { configurable } from 'torii/configuration';
  class CognitoToriiProvider extends _oauth2Code.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "name", 'cognito');

      _defineProperty(this, "baseUrl", _environment.default.torii.providers.cognito.authUri);

      _defineProperty(this, "responseParams", ['code']);

      _defineProperty(this, "redirectUri", _environment.default.torii.providers.cognito.redirectUri);
    }

    // redirectUri = function () {
    //   return [
    //     window.location.protocol,
    //     '//',
    //     window.location.host,
    //     '/torii/redirect.html'
    //   ].join('');
    // }
    fetch(data) {
      return data;
    } // TODO: Delete these functions since we only included them for debugging

    /**
     * @method open
     * @return {Promise<object>} If the authorization attempt is a success,
     * the promise will resolve an object containing the following keys:
     *   - authorizationCode: The `code` from the 3rd-party provider
     *   - provider: The name of the provider (i.e., google-oauth2)
     *   - redirectUri: The redirect uri (some server-side exchange flows require this)
     * If there was an error or the user either canceled the authorization or
     * closed the popup window, the promise rejects.
     */


    open(options) {
      const name = this.get('name');
      const url = this.buildUrl();
      const redirectUri = this.get('redirectUri');
      const responseParams = this.get('responseParams');
      const responseType = this.get('responseType');
      const state = this.get('state');
      const shouldCheckState = responseParams.indexOf('state') !== -1;
      console.log('Redirect', redirectUri);
      console.log('url', url);
      console.log('responseParams', responseParams);
      console.log('options', options);
      return this.get('popup').open(url, responseParams, options).then(function (authData) {
        const missingResponseParams = [];
        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });

        if (missingResponseParams.length) {
          throw new Error('The response from the provider is missing ' + 'these required response params: ' + missingResponseParams.join(', '));
        }

        if (shouldCheckState && authData.state !== state) {
          throw new Error('The response from the provider has an incorrect ' + 'session state param: should be "' + state + '", ' + 'but is "' + authData.state + '"');
        }

        return {
          authorizationCode: decodeURIComponent(authData[responseType]),
          provider: name,
          redirectUri: redirectUri,
          state: state
        };
      });
    } // open ()


    buildQueryString() {
      const requiredParams = this.get('requiredUrlParams');
      const optionalParams = this.get('optionalUrlParams');

      const qs = _queryString.default.create({
        provider: this,
        requiredParams: requiredParams,
        optionalParams: optionalParams
      });

      return qs.toString();
    }

    buildUrl() {
      const base = this.get('baseUrl');
      const qs = this.buildQueryString();
      return [base, qs].join('?');
    }

  }

  _exports.default = CognitoToriiProvider;
});