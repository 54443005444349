define("rate-your-tow-ui/controllers/members/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MembersEditController extends Ember.Controller {}

  _exports.default = MembersEditController;
});