define("rate-your-tow-ui/controllers/member_modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MemberModalController = (_dec = Ember._action, _dec2 = Ember._action, (_class = class MemberModalController extends Ember.Controller {
    // This is required to cleanup after we inject the modal render.
    doClose() {
      const appRoute = Ember.getOwner(this).lookup('route:application');
      appRoute.disconnectOutlet({
        outlet: 'modal',
        parentView: 'application'
      });
    }

    submitForm(event) {
      // event.preventDefault();
      console.log(event);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "doClose", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "doClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitForm", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype)), _class));
  _exports.default = MemberModalController;
});